import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import Footer from "./footer"
import Ad from "./adsense"
import "./layout.scss"
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

const Layout = ({ children }) => (
  <>
  <Container>
    <Ad />
    <main >{children}</main>
    <Ad />
  </Container>
  <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 1.45rem 3vw 2.9rem;
`

export default Layout
