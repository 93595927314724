import { css } from 'styled-components'

const mixinOutline = css`
  text-shadow: #fff 2px 0px,  #fff -2px 0px,
  #fff 0px -2px, #fff 0px 2px,
  #fff 2px 2px , #fff -2px 2px,
  #fff 2px -2px, #fff -2px -2px,
  #fff 1px 2px,  #fff -1px 2px,
  #fff 1px -2px, #fff -1px -2px,
  #fff 2px 1px,  #fff -2px 1px,
  #fff 2px -1px, #fff -2px -1px;
`;

export default mixinOutline