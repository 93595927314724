import React from "react"
import AdSense from "react-adsense"
import styled from "styled-components"

const Ad = () => (
  <Container>
    <AdSense.Google
      style={{ display: "block" }}
      client="ca-pub-7927411979252205"
      slot="9989729069"
      format="auto"
      responsive="true"
    />
  </Container>
)

const Container = styled.div`
  margin: 20px 0;
`
export default Ad
